<template>
  <div>
    <ejs-dialog
      ref="createSuppliesCodeDetailPopup"
      width="1180"
      :header="`저장품 코드 상세/추가`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :cssClass=" isNew ? $t('className.popup.productCodePopupAdd') : $t('className.popup.productCodePopupModify')"
      @close="onClickClose"
    >
      <div class="window suppliesCodeDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <!-- 팝업 컨텐츠 영역 -->
            <div class="content-body">
              <div class="body-article">
                <!-- SECTION 1 -->
                <section class="article-section section-01">
                  <!-- 타이틀 -->
                  <div class="section-header">
                    <div class="header-left"><div class="header-title">저장품 코드 상세/추가</div></div>
                    <div class="header-right"><ul class="header-button"><!-- 버튼 --></ul></div>
                  </div>
                  <!-- 상세 필드 -->
                  <div class="section-body">
                    <article class="body-article">
                      <div class="article-left">
                        <section class="article-section section-0101">
                          <!-- SUB 타이틀 -->
                          <div class="section-header">
                            <div class="header-left"><div class="header-title">저장품 기본정보</div></div>
                          </div>
                          <!-- SUB 상세필드 -->
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">

                                    <li class="field">
                                      <div class="title required">품명</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><input-text  ref="suppliesName" id="suppliesName" v-model="popupInputField.suppliesName"/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">코드</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><input-text ref="suppliesCode" id="suppliesCode" v-model="popupInputField.suppliesCode" disabled/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field  correspondent">
                                      <div class="title required">분류계층</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group input">
                                            <div class="form">
                                              <input-text ref="pathDesc" id="pathDesc"  v-model="popupInputField.pathDesc" disabled/>
                                            </div>
                                          </li>
                                          <li class="item form-group button">
                                            <ul class="button">
                                              <li class="search"><erp-button button-div="GET" @click.native="onClickStockTreePopupShow">검색</erp-button></li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">유류</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <ul class="check">
                                              <li>
                                                <label><input type="checkbox" id="oilFlag" ref="oilFlag" v-model="popupInputField.oilFlag"/><i/></label>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks">
                                      <div class="title">규격</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><input-text ref="standard" id="standard"  v-model="popupInputField.standard"/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title required">단위</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><input-text ref="unit" id="unit"  v-model="popupInputField.unit"/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">최근입고가</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><input-number ref="price" id="price"  v-model="popupInputField.price" style="padding: 0 3px;"/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">면과세</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form"><ejs-dropdownlist ref="taxDiv" v-model="popupInputField.taxDiv" :dataSource="popupInputFieldOptions.taxDiv" :fields="popupInputFieldOptions.fields" cssClass="body-data-dropdown"/></div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field">
                                      <div class="title">사용여부</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <ul class="check">
                                              <li>
                                                <label><input type="checkbox" id="useFlag" ref="useFlag" v-model="popupInputField.useFlag"/><i/></label>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="field remarks">
                                      <div class="title">비고</div>
                                      <div class="content">
                                        <ul class="row">
                                          <li class="item form-group">
                                            <div class="form">
                                              <input-textarea ref="remarks" id="remarks" name="remarks" v-model="popupInputField.remarks"/>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div class="article-right">
                        <section class="article-section section-0103">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">사진</div>
                            </div>
                            <div class="header-right">
                              <ul class="header-button">
                                <li class="registration">
                                  <ejs-uploader
                                    ref="fileUploader"
                                    name="fileUploader"
                                    maxFileSize="10000000"
                                    :buttons="buttons"
                                    :allowedExtensions="imageFileUploaderExtensions"
                                    :multiple="false"
                                    :selected="onFileSelect"
                                  ></ejs-uploader>
                                </li>
                                <li class="delete">
                                  <erp-button button-div="DELETE" :is-icon-custom="true" @click.native="onDeleteImage">삭제</erp-button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-photo" id="suppliseImagePreviewContainer">
                              <div
                                v-if="popupInputField.imagePath"
                                id="previewImage"
                                class="item"
                                :style="{backgroundImage:'url(' + popupInputField.imagePath + ')'}"
                              ></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                    <article class="body-article">
                      <div class="article">
                        <section class="article-section section-0102">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">성분</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">

                                    <li class="field">
                                      <div class="title">N</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntN"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">K</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntK"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">FE</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntFe"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">ZN</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntZn"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">B</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntB"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">MN</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntMn"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">S</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntS"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">P</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntP"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">MG</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntMg"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">NA</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntNa"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">SI</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntSi"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">CA</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntCa"/></div></li></ul></div>
                                    </li>

                                    <li class="field">
                                      <div class="title">MO</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntMo"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">AL</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntAl"/></div></li></ul></div>
                                    </li>
                                    <li class="field">
                                      <div class="title">OM</div>
                                      <div class="content"><ul class="row"><li class="item form-group"><div class="form"><input-number :allowDot=true  v-model="popupInputField.irdntOm"/></div></li></ul></div>
                                    </li>
                                  </ul>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-top"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                              <div class="border-left"></div>
                              <div class="border-right"></div>
                              <div class="border-top"></div>
                              <div class="border-bottom"></div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </article>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button button-div="SAVE" :ignore="isPopupOpened" :is-key-color="true" :is-shortcut-button="true" @click.native="onClickSave">저장</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>

    <tree-popup
        ref="stockTreePopup"
        v-if="isShowTreePopup"
        @popupClosed="onTreePopupClose"
        @popupConfirm="onTreePopupConfirm"
    ></tree-popup>
  </div>
</template>

<script>
import CommonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ConfirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputText from "@/components/common/text/InputText.vue";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import TreePopup from "@/views/stock-management/common/StockTreePopup.vue";
import { validateFormRefs, uploadImage } from "@/utils/formUtil";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import StockSuppliesStd from "@/api/v2/StockManage/StockSuppliesStd";
import StockCommon from "@/api/v2/StockManage/StockCommon";

export default {
  name: "suppliesCodeDetailPopup",
  mixins: [CommonMixin, MessagePopupDialogMixin, ConfirmDialogMixin],
  components:{ ErpButton, InputText,InputTextarea, InputNumber, TreePopup},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      isNew: true,
      isShowTreePopup: false,
      isImageUploadedAndNotSaved: false,
      
      popupInputField: {
        suppliesCode : null,
        suppliesName : null,
        classCode    : null,
        standard     : null,
        unit         : null,
        price        : null,
        taxDiv       : 'TAX',
        remarks      : null,
        imageId      : null,
        imagePath    : null,
        useFlag      : true,
        oilFlag      : false,
        irdntN       : null,
        irdntZn      : null,
        irdntS       : null,
        irdntNa      : null,
        irdntMo      : null,
        irdntK       : null,
        irdntB       : null,
        irdntP       : null,
        irdntSi      : null,
        irdntAl      : null,
        irdntFe      : null,
        irdntMn      : null,
        irdntMg      : null,
        irdntCa      : null,
        irdntOm      : null,

        pathDesc : null,
      },

      popupInputFieldOptions: {
        taxDiv: commonCodesGetCommonCode("NOTAX_CODE",false,false,"",""),
        fields: { text: "comName", value: "comCode" },
      },
      //validate 체크 항목
      validateRefField: {
        suppliesName: { required: true },
        pathDesc: { required: true },
        taxDiv: { required: true },
        unit: { required: true },
      },
      buttons: {
        browse: "등록",
      },
      imageFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 .... 팝업이 없으면 false 리턴
    isPopupOpened() {
      return this.isShowTreePopup; //
    },
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted(){
  },
  async beforeDestroy() {
    if (this.isImageUploadedAndNotSaved) {
      await this.onDeleteImage();
    }
  },

  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //******************************* 공통 버튼 이벤트 *******************************

    //******************************* 공통 이벤트 *******************************
    //필수입력체크
    validateFormRefs,
    //팝업화면 초기화(신규,수정)
    async initShow(isNew, searchCode) {
      this.isNew = isNew;
      if (!this.isNew) {
         const args = {suppliesCode: searchCode};
         this.popupInputField = await StockSuppliesStd.getSuppliesCodeDetail(args);
      } else {
        //todo: 신규 입력시 디폴트 값 설정
      }
    },
    async onClickSave(){
      //1.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }
      if(this.isNew){
        await StockSuppliesStd.postSuppliesCodeDetail(this.popupInputField);
      } else{
        await StockSuppliesStd.patchSuppliesCodeDetail(this.popupInputField);
      }
      this.isImageUploadedAndNotSaved = false;
      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      //5. 부모창 저장후 이벤트 호출
      this.$emit('popupSaved');
    },
    // 닫기
    async onClickClose() {
      //이미지 등록후 저장안되어있으면 이미지 삭제
      if (this.isImageUploadedAndNotSaved) {
        await this.onDeleteImage();
      }
      this.$emit("popupClosed", false);
    },
    //******************************* api 호출 *******************************
    onClickStockTreePopupShow() {
      this.isShowTreePopup = true;
      this.setTreeData();
    },
    async setTreeData(){
      let treeField ={};
      this.treeData = await StockCommon.getSuppliesClassList({useFlag:'Y'});
      this.treeData.forEach((item)=>{
        if(item.parentCode==="0")
        {
          item.parentCode=null;
        }
        item.hasChild = true;
      });
      treeField.fields = { dataSource: this.treeData, id: 'classCode', parentID: 'parentCode', text: 'classCodeName', hasChildren: 'hasChild', selected: 'isSelected' };
      this.$nextTick(() => {
        this.$refs.stockTreePopup.initShow( "분류코드선택", treeField );
      });
    },
    onTreePopupConfirm(selectTreeView) {
      this.popupInputField.pathDesc = this.treeData.filter (item => item.classCode === selectTreeView.id)[0].pathDesc;
      this.popupInputField.classCode = selectTreeView.id;
      this.isShowTreePopup = false;
    },
    onTreePopupClose() {
      this.isShowTreePopup = false;
      this.popupInputField.bcncName = null;
      this.popupInputField.classCode = null;
    },
    //region ******************************* 이미지 첨부  *******************************
    async onFileSelect(args) {
      const file = args.event.target.files[0];

      try {
        const uploadResponse = await uploadImage(
          this.popupInputField.imageId,
          "SUPPLIES",
          args.filesData,
          file
        );
        if (uploadResponse) {
          this.popupInputField.imageId = uploadResponse.imageId;
          this.popupInputField.imagePath = uploadResponse.imageURL;
          this.isImageUploadedAndNotSaved = true;
        }
      } catch (e) {
        alert(e.message);
      } finally {
        this.$refs.fileUploader.clearAll(); // 다시 upload 하지 않도록 clear
      }
    },

    async onDeleteImage() {
      this.$refs.fileUploader.clearAll();
      try {
        if (this.popupInputField.imageId) {
          await GolfErpAPI.deleteImage(this.popupInputField.imageId);
        }
        this.popupInputField.imageId = null;
        this.popupInputField.imagePath = null;
        this.isImageUploadedAndNotSaved = false;
      } catch (e) {
        console.error("imageDelete.err.===>", e);
      }
    },
    //endregion ******************************* 이미지 첨부  *******************************
  },
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .window.suppliesCodeDetail {min-width: calc(1180px - 2px);}

body .window.suppliesCodeDetail .windowContent .body-article .body-article {height: auto;}
body .window.suppliesCodeDetail .windowContent .body-article .body-article .article-left {width: calc(100% - 362px);}
body .window.suppliesCodeDetail .windowContent .body-article .body-article .article-right {width: 362px;}
body .window.suppliesCodeDetail .windowContent .body-article .body-article .article-section {height: auto;}
body .window.suppliesCodeDetail .windowContent .body-article .body-article .section-body {overflow: visible; border: none;}

body .window.suppliesCodeDetail .windowContent .article-section.section-0101 .body-data .field {width: calc((50%) + 1px);}
body .window.suppliesCodeDetail .windowContent .article-section.section-0101 .body-data .field.correspondent .content .item.input {width: calc(100% - 22px);}
body .window.suppliesCodeDetail .windowContent .article-section.section-0101 .body-data .field.correspondent .content .item.button {width: 22px;}
body .window.suppliesCodeDetail .windowContent .article-section.section-0101 .body-data .field.remarks {width: calc(100% + 1px);}

body .window.suppliesCodeDetail .windowContent .article-section.section-0102 .body-data .field {width: calc((100% / 3) - 1px);}
body .window.suppliesCodeDetail .windowContent .article-section.section-0102 .body-data .field.remarks {width: calc(100% + 1px);}
</style>
