<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title"> 분류</div>
            <ul class="content">
              <li class="item" ><div class="form">
                <input-text id="classCodeName" ref="classCodeName" v-model="searchField.classCodeName"  @change="onClassCodeNameChanged"/>
              </div></li>
              <li class="item form-group"><ul class="button"><li class="search"><erp-button button-div="GET" @click.native="onClcikTreePopupShow">검색</erp-button></li></ul></li>
            </ul>
          </li>
          <li class="field">
            <div class="title"> 저장품명</div>
            <ul class="content">
              <li class="item"><input-text id="suppliesName" ref="suppliesName" v-model="searchField.suppliesName" @keydown.enter="onClickSearch"/></li>
            </ul>
          </li>
          <li class="field">
            <div class="title">면/과세</div>
            <ul class="content">
              <li class="item" >
                <ejs-dropdownlist ref="searchEqpDiv" v-model="searchField.taxDiv" :dataSource="commonCodeOptions.taxDiv"
                                  :allowFiltering="false" :fields="commonCodeOptions.fields" cssClass="lookup-condition-dropdown"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용여부</div>
            <ul class="content">
              <li class="item" >
                <ejs-dropdownlist v-model="searchField.useFlag" :dataSource="commonCodeOptions.useFlag"
                                  :allowFiltering="false" :fields="commonCodeOptions.fields" cssClass="lookup-condition-dropdown"/></li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" @click.native = "onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right">
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">저장품 목록</div>
              <div class="header-caption">[ {{ gridField.count }} 건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">  <erp-button button-div="SAVE" @click.native="onClickAdd">추가</erp-button></li>
                <li class="print"><erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="grid"
                v-bind ="initGrid"
                @recordClick="onGridClick"
                @queryCellInfo="onQueryCellInfo"
                @headerCellInfo="onHeaderCellInfo"
                @actionComplete="gridComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
    <supplies-code-detail-popup
        v-if="isSuppliesCodeDetailPopupOpen"
        ref="suppliesCodeDetailPopup"
        @popupClosed="closeSuppliesCodeDetailPopup"
        @popupSaved="saveSuppliesCodeDetailPopup"
    />
    <stock-tree-popup
        v-if="isStockTreePopup"
        ref="stockTreePopup"
        @popupClosed="closedStockTreePopup"
        @popupConfirm="confirmStockTreePopup"
    ></stock-tree-popup>
  </div>
</template>

<script>

import CommonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputText from "@/components/common/text/InputText.vue";
import SuppliesCodeDetailPopup from "@/views/stock-management/supplies/popup/SuppliesCodeDetailPopup.vue";
import StockTreePopup from "@/views/stock-management/common/StockTreePopup.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import StockSuppliesStd from "@/api/v2/StockManage/StockSuppliesStd";

export default {
  name: "SuppliesCodeRegistration",
  mixins : [CommonMixin, MessagePopupDialogMixin],
  components:{StockTreePopup, EjsGridWrapper, ErpButton, ReportView, SuppliesCodeDetailPopup, InputText },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //팝업오픈 여부
      isSuppliesCodeDetailPopupOpen : false,
      isStockTreePopup : false,
      //필터 데이타
      searchField: {
        classCodeName: null,
        classCode : null,
        suppliesName : null,
        taxDiv: "",
        useFlag: "",
      },
      //드롭다운 코드
      commonCodeOptions: {
        taxDiv: commonCodesGetCommonCode("NOTAX_CODE",false,true,"",""),
        useFlag: [
            {comName:"" ,comCode:""},
            {comName:"사용" ,comCode:"1"},
            {comName:"미사용" ,comCode:"0"},
        ],
        fields: { text: "comName", value: "comCode" },
      },
      // 그리드 데이타
      gridField:{
        count : 0,
        dataSource: [],
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    //모든 팝업 오픈 여부
    isPopupOpened(){
      return this.isSuppliesCodeDetailPopupOpen||this.isStockTreePopup;
    },
    //그리드 세팅
    initGrid(){
      return{
        columns:  [
          {field: 'pathDesc',       textAlign: 'left',      width: 250,  visible: true,   allowEditing : false,  type: 'string',   headerText: '분류계층'  },
          {field: 'suppliesName',   textAlign: 'left',      width: 200,  visible: true,   allowEditing : false,  type: 'string',   headerText: '저장품명'  },
          {field: 'suppliesCode',   textAlign: 'center',    width: 100,   visible: true,   allowEditing : false,  type: 'string',   headerText: '저장품코드' },
          {field: 'standard',       textAlign: 'left',      width: 100,  visible: true,   allowEditing : false,  type: 'string',   headerText: '규격'  },
          {field: 'unit',           textAlign: 'left',      width: 100,   visible: true,   allowEditing : false,  type: 'string',   headerText: '단위' },
          {field: 'taxDiv',         textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'string',   headerText: '면과세',   isCommonCodeField: true, dataSource: this.commonCodeOptions.taxDiv,},
          {field: 'price',          textAlign: 'right',     width: 100,   visible: true,   allowEditing : false,  type: 'number',   headerText: '최근입고가',     isNumericType: true,},
          {field: 'oilFlag',        textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '유류',    displayAsCheckBox: true, editType: "booleanedit" },
          {field: 'useFlag',        textAlign: 'center',    width: 80,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '사용여부', displayAsCheckBox: true, editType: "booleanedit" },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
    //검색
    this.findList();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //******************************* 공통 버튼 이벤트 *******************************
    //조회
    onClickSearch(){
      this.findList();
    },
    //추가
    onClickAdd() {
      this.openSuppliesCodeDetailPopup();
    },
    //저장
    onClickSave() {
    },
    //삭제
    onClickDelete() {
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: "저장품코드.xlsx"});
    },
    //리포트 출력
    onClickPrint() {
    },
    //******************************* api 호출 *******************************
    async findList() {
      this.gridField.dataSource = await StockSuppliesStd.getSuppliesCodeList(this.searchField);
    },
    //******************************* 공통 이벤트 *******************************

    //저장품 분류 텍스트박스 변경시 코드 초기화
    onClassCodeNameChanged(args){
      this.searchField.classCode = null;
      if (args.event) {
        if (args.value.trim() === "") {
          // 공백,빈 칸인 경우에는 텍스트 영역 클리어
          this.searchField.classCodeName = null;
        }
      }
    },
    //필터: 저장분 분류 트리
    //팝업 인터페이스
    //팝업 트리
    onClcikTreePopupShow() {
      this.isStockTreePopup = true;
      this.setTreeData();
    },
    confirmStockTreePopup(selectTreeView){
      this.isStockTreePopup = false;
      this.searchField.classCodeName = selectTreeView.text;
      this.searchField.classCode = selectTreeView.id;
    },
    //트리팝업닫기
    closedStockTreePopup() {
      this.isStockTreePopup = false;
    },
    //
    async setTreeData(){
      let treeField ={};
      this.treeData = await StockSuppliesStd.getSuppliesClassList({useFlag:'Y'});
      this.treeData.forEach((item)=>{
        if(item.parentCode==="0")
        {
          item.parentCode=null;
        }
        item.hasChild = true;
      });
      treeField.fields = { dataSource: this.treeData, id: 'classCode', parentID: 'parentCode', text: 'classCodeName', hasChildren: 'hasChild', selected: 'isSelected' };
      this.$nextTick(() => {
        this.$refs.stockTreePopup.initShow( "분류코드선택", treeField );
      });
    },
    //상세 팝업열기
    openSuppliesCodeDetailPopup(isNew=true,searchCode=null){
      this.isSuppliesCodeDetailPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.suppliesCodeDetailPopup.initShow(isNew, searchCode);
      });
    },
    //상세 팝업닫기
    closeSuppliesCodeDetailPopup(){
      this.isSuppliesCodeDetailPopupOpen = false;
    },
    //상세 팝업 저장후 본문조회
    async saveSuppliesCodeDetailPopup(){
      this.isSuppliesCodeDetailPopupOpen = false;
      await this.findList();
    },

    //******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['suppliesName','suppliesCode'].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['suppliesName','suppliesCode'].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 클릭
    onGridClick(args){
      const {column:{field}, rowData} = args;
      if (['suppliesName','suppliesCode'].includes(field)) {
        this.openSuppliesCodeDetailPopup(false, rowData.suppliesCode);
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
  }

};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
</style>
